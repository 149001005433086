<template>
  <div class="recharge">
    <!-- 导航栏 -->
    <van-nav-bar :title="$t('newAdd.Recharge')">
      <van-icon slot="left" name="arrow-left" color="#fff" @click="$router.back()" />
      <template #right>
        <button @click="$router.push('/recharge-list')" style="
            padding: calc(6rem / 16) calc(12rem / 16);
            background: #fff;
            color: #232f3e;
            border-radius: 4px;
            font-size: calc(14rem / 16);
          ">
          {{ $t('newAdd.Record') }}
        </button>
      </template>
    </van-nav-bar>

    <!-- language-ch 充值账户：￥ -->
    <van-field center v-model="account" disabled :label="$t('newAdd.MemberAccount')"
      :placeholder="$t('newAdd.AccountPlaceholder')" />
    <!-- language-ch 充值金额 -->
    <!-- 允许输入数字，调起带符号的纯数字键盘 -->
    <van-field center class="number" v-model="number" type="number" :label="`${$t('newAdd.RechargeAmount')}\n`"
      :placeholder="$t('newAdd.RechargeAmountPlaceholder')" />

    <van-grid :gutter="10" class="grid">
      <van-grid-item class="grid_item" v-for="value in countList" :key="value" @click="addClass(value)">
        <div slot="text" :class="{ active: number === value }">
          <span v-if="country != 'tr'">{{ verConfig.currency }}</span>
          <span v-if="country == 'tr'">TRY</span> {{ value }}
        </div>
      </van-grid-item>
    </van-grid>

    <van-cell title="单元格">
      <!-- language-ch 选择充值方式 -->
      <span slot="title" class="list-title-text">{{
        $t('newAdd.SelectRechargeMethod')
      }}</span>
      <!-- language-ch 充值教程 -->
      <!-- <span slot="default" class="list-title-rtext" @click="showTutorial = true"
        >Top-up tutorial</span
      > -->
    </van-cell>

    <div class="tutorial" v-show="showTutorial">
      <img :src="require('@/assets/images/user_pay/step/' + addCount + '.jpg')" @click="changeImg" />
    </div>

    <!-- 标签页 支付方式-->
    <van-tabs v-model="active" swipeable class="tabs" line-height="2px" line-width="15px">
      <van-tab v-for="item in tabPages" :key="item.type">
        <span slot="title">{{ item.title }}</span>
        <div v-if="item.lists.length > 0">
          <div class="mode" v-for="value in item.lists" :key="value.id" @click="paySubmit(value)">
            <img v-if="item.type !== 'idpay'" class="step-image"
              :src="require(`@/assets/images/user_pay/${item.type}.png`)" />
            <div class="step">
              <h5>{{ value.title }}</h5>
              <p>{{ value.desc }}</p>
            </div>
            <img v-if="value.letter == 1" class="tabbar-letter" :src="require('@/assets/images/user_pay/letter.png')" />
          </div>
        </div>
        <div v-else class="tips">
          {{ $t('newAdd.HaveNoteMethod') }}
        </div>
      </van-tab>
    </van-tabs>
    <!-- <Tabbar active="new-recharge" /> -->
  </div>
</template>

<script>
import {
  GET_CONFIG_INFO,
  GET_RECHARGE_LIST,
  getUserInfoNew,
  RECHARGE_COMMIT,
} from '@/api'
import Cookies from 'js-cookie'
import { getUserInfo } from '@/utils/tools'
import Tabbar from '@/components/Tabbar'
import {
  NavBar,
  cell,
  icon,
  field,
  Dialog,
  Toast,
  Grid,
  GridItem,
  tabs,
  tab,
} from 'vant'
import { mapGetters } from 'vuex'

export default {
  components: {
    [NavBar.name]: NavBar,
    [cell.name]: cell,
    [icon.name]: icon,
    [field.name]: field,
    [Toast.name]: Toast,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [tabs.name]: tabs,
    [tab.name]: tab,
    [Dialog.Component.name]: Dialog.Component,
    Tabbar,
  },
  data() {
    return {
      // countList: [
      //   "100",
      //   "200",
      //   "500",
      //   "1000",
      //   "5000",
      //   "10000",
      //   "30000",
      //   "50000",
      // ],
      countList: [
        '100',
        '300',
        '500',
        '1000',
        '3000',
        '5000',
        '10000',
        '30000',
      ],
      // account: '',
      number: 100,
      active: 0,
      tabPages: [],
      showTutorial: false,
      count: 1,
      serviceURL: '',
      country: Cookies.get('language'),
    }
  },
  created() {
    // getUserInfoNew()
    this.getRechargeList();
    this.$store.dispatch('getUserInfo');
    this.$store.dispatch("updateTaskUserInfo");
    // this.getLists()
    // this.getService()
    // console.log(Cookies.get('language'));
  },
  mounted() {
    // this.account = getUserInfo().userphone
  },
  methods: {
    getRechargeList() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      })
      GET_RECHARGE_LIST()
        .then((r) => {
          Toast.clear()
          if (r.data.ret === 1) {
            Toast.clear()
            this.tabPages = r.data.data
          }
        })
        .catch((e) => {
          Toast.clear()
        })
    },
    // 将点击项的值绑定给充值金额框
    addClass(value) {
      this.number = value
    },

    // 获取充值方式的数据
    async getLists() {
      const {
        data: { data, ret },
      } = await request({
        method: 'get',
        url: 'pay/lists',
      })
      if (ret === 1) {
        this.tabPages = data
      }
    },

    // async getService () {
    //   const { data: { data, ret } } = await request({
    //     method: 'get',
    //     url: 'other/config',
    //     params: {
    //       act: 'system',
    //       refresh: this.isLoading === true ? 1 : 0
    //     }
    //   })
    //   if (ret === 1) {
    //     this.serviceURL = data.service_url
    //   }
    // },
    // 教程
    changeImg() {
      this.count += 1
      if (this.count === 6) {
        this.showTutorial = false
        this.count = 1
      }
    },

    handlerService() {
      //   Toast({
      //     message: 'Try again later'
      //   })
      window.location.href = this.serviceURL
    },

    paySubmit(item) {
      // console.log(item);
      Toast.loading({
        forbidClick: true,
        duration: 0,
      })
      RECHARGE_COMMIT({
        id: item.id,
        money: this.number,
        account: this.account,
        // isbefore: 1,
      })
        .then((r) => {
          Toast.clear()
          if (r.data.ret === 2) {
            Toast.clear()
            window.location.href = r.data.data.url
          } else if (r.data.ret === 1) {
            if (r.data.data.bank.indexOf('qrcode') != -1) {
              r.data.data.step = item.step
              r.data.data.id = item.id
              this.$router.push({
                name: 'qrPay',
                params: {
                  payData: r.data.data,
                },
              })
            }
          } else if (r.data.ret === 3) {
            if (r.data.data.bank.indexOf('bank-BANK') != -1) {
              r.data.data.step = item.step
              r.data.data.id = item.id
              this.$router.push({
                name: 'bankPay',
                params: {
                  payData: r.data.data,
                },
              })
            }
          } else {
            // Toast('System exception, please try again later')
            Toast(r.data.msg)
          }
        })
        .catch((e) => {
          Toast.clear()
          console.error(e)
        })
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
    addCount() {
      return this.count
    },
    account() {
      return this.userInfo.userphone
    }
  },
}
</script>

<style lang="less" scoped>
@sizeColor: #232f3e;
.recharge {
  position: relative;
  font-family: 'PingFang-Regular';
  height: 100%;

  .van-nav-bar {
    // background-color: #ff0000;

    /deep/ .van-nav-bar__content {
      background-color: var(--navBg);
      height: calc(50rem / 16);
    }

    /deep/.van-nav-bar__title {
      color: #fff;
    }

    button {
      background-color: var(--navBg);
      border: none;
      color: #fff;
      font-size: calc(10rem / 16);
    }

    .iconfont {
      font-size: calc(22rem / 16);
      color: #fff;
    }
  }

  .van-field {
    margin: calc(6rem / 16) 0;

    /deep/.van-field__label {
      width: calc(177rem / 16) !important;
    }

    /deep/.van-field__body {
      input {
        text-align: center;
      }
    }
  }

  .number {
    /deep/.van-field__body {
      input {
        text-align: center;
        color: #ff0000;
      }
    }
  }

  .grid {
    margin: calc(7.5rem / 16) 0;

    .grid_item {
      /deep/ .van-grid-item__content {
        padding: 0;

        div {
          width: 100%;
          height: calc(41rem / 16);
          font-size: calc(13rem / 16);
          line-height: calc(45rem / 16);
          text-align: center;
          color: @sizeColor;
          background-color: #ffffff;
        }

        .active {
          color: #ffffff;
          background-color: var(--navBg);
        }
      }
    }
  }

  .van-cell {
    background-color: transparent;
  }

  .list-title-text {
    font-size: calc(13rem / 16);
  }

  .list-title-rtext {
    color: #a87cf3;
    font-size: calc(13rem / 16);
    text-decoration: underline;
  }

  .tabs {
    .mode {
      position: relative;
      display: flex;
      box-sizing: border-box;
      width: 100%;
      margin-top: calc(7.5rem / 16);
      padding: calc(7.5rem / 16);
      background-color: #fff;
    }

    .step {
      font-size: calc(12rem / 16);

      h5 {
        margin: 0 0 calc(7.5rem / 16) 0;
      }
    }

    .tabbar-letter {
      position: absolute;
      top: 0;
      right: 0;
      width: calc(32.5rem / 16);
      height: calc(32.5rem / 16);
    }

    .step-image {
      width: calc(50rem / 16);
      height: calc(50rem / 16);
      margin-right: calc(10rem / 16);
    }

    .tips {
      text-align: center;
      margin-top: calc(45rem / 16);
      font-size: calc(10rem / 16);
    }
  }

  .tutorial {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    height: 100%;

    img {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
